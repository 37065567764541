const QuillWritingIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <circle fill="none" cx="18" cy="18" r="18"/>
            <path d="M14.75 26c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 12.75 24v-3h3v-2.25a4.885 4.885 0 0 1-1.662-.388 4.387 4.387 0 0 1-1.438-1.012v-1.1H11.5L8.25 13a4.869 4.869 0 0 1 2.225-1.625 7.876 7.876 0 0 1 2.675-.475c.45 0 .888.033 1.313.1.425.067.854.192 1.287.375V10h12v13c0 .833-.292 1.542-.875 2.125A2.893 2.893 0 0 1 24.75 26h-10zm3-5h6v2c0 .283.096.52.288.712a.965.965 0 0 0 .712.288c.283 0 .52-.096.712-.288A.965.965 0 0 0 25.75 23V12h-8v.6l6 6V20h-1.4l-2.85-2.85-.2.2c-.233.233-.48.442-.738.625a3.287 3.287 0 0 1-.812.425V21zm-5.4-6.75h2.3v2.15c.2.133.408.225.625.275.217.05.442.075.675.075.383 0 .73-.058 1.038-.175.308-.117.612-.325.912-.625l.2-.2-1.4-1.4a4.851 4.851 0 0 0-1.625-1.088 5.11 5.11 0 0 0-1.925-.362c-.333 0-.65.025-.95.075-.3.05-.6.125-.9.225l1.05 1.05z" fill="currentColor" fillRule="nonzero"/>
        </g>
    </svg>
  )
}

export default QuillWritingIcon
