import { useCallback, useEffect, useRef } from 'preact/hooks'

import clsx from 'clsx'
import { MDCTextField } from '@material/textfield';
import { bindStreetAddressAutocomplete, unbindStreetAddressAutocomplete } from 'landing_pages/address_autocomplete';
import Inputmask from 'inputmask'


type InputType = 'email' | 'number' | 'password' | 'tel' | 'text' | 'url';

export type MapsAutocompleteHandler = (result: {
  StreetAddress: string
  City: string
  State: string
  ZIP_Code: string
}) => void

type PreactTextFieldProps = {
  type?: InputType,
  label?: string,
  value?: string,
  isError?: boolean,
  isDisabled?: boolean,
  inputMask?: string,
  mapsAutoComplete?: MapsAutocompleteHandler,
  onInput?: React.ChangeEventHandler<HTMLInputElement> | undefined,
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined,
}

export default function PreactTextField({ type, label, value, isError, isDisabled, inputMask, mapsAutoComplete, onInput, onChange}: PreactTextFieldProps) {
  const ref = useRef<MDCTextField>(null)

  const setFieldRef = useCallback(field => {
    if(ref.current) {
      ref.current.destroy()
    }
    if(field) {
      ref.current = new MDCTextField(field)
      const foundation = ref.current['foundation']
      foundation.setValidateOnValueChange(false)
    } else {
      ref.current = null
    }
  }, [])

  const inputRef = useRef<HTMLInputElement>(null)

  const setInputRef = useCallback(input => {
    if(inputRef.current) {
      if(inputMask) {
        Inputmask.remove(inputRef.current)
      }
      if(mapsAutoComplete) {
        unbindStreetAddressAutocomplete() // FIXME: this unbinds autocomplete globally, rather than just for this specific instance. Ideally this wouldn't be wrapped in if(mapsAutoComplete)
      }
    }

    inputRef.current = input

    if(inputRef.current) {
      if(inputMask) {
        Inputmask({ mask: inputMask }).mask([inputRef.current])
      }
      if(mapsAutoComplete) {
        bindStreetAddressAutocomplete(inputRef.current, mapsAutoComplete)
      }
    }
  }, [inputMask, mapsAutoComplete])

  // Setting the value of the mdc-textfield ensure the character counter and floating label are handled
  // Using it's current value skips setting the native input value (which caused bugs when used with masking)
  // Detail here: https://github.com/material-components/material-components-web/blob/2ab716cbda14aca5a8b62cdae3c71c2d629b16f7/packages/mdc-textfield/foundation.ts#L327-L346
  useEffect(() => {
    if(ref.current) {
        // eslint-disable-next-line no-self-assign
        ref.current.value = ref.current.value
    }
  }, [ref?.current?.value])

  return (
    <label className={clsx("preact-text-field", "mdc-text-field", "mdc-text-field--outlined", isError && 'mdc-text-field--invalid')} ref={setFieldRef}>
      <span className="mdc-notched-outline">
        <span className="mdc-notched-outline__leading"></span>
        <span className="mdc-notched-outline__notch">
          <span className="mdc-floating-label" id="my-label-id">{label}</span>
        </span>
        <span className="mdc-notched-outline__trailing"></span>
      </span>
      <input type={type} value={value} onInput={onInput} onChange={mapsAutoComplete ? undefined : onChange} disabled={isDisabled} className="mdc-text-field__input" aria-labelledby="my-label-id" inputMode={type === 'tel' ? 'numeric' : 'text'} ref={setInputRef} />
    </label>
  )
}
