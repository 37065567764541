import { Notifier } from '@airbrake/browser';

const airbrake = new Notifier({
  projectId: 255032,
  projectKey: '2801ddefd2ee657030ffbde6178b9cd5'
});

airbrake.addFilter(notice => {
  notice.context.environment = process.env.RAILS_ENV;
  if(notice.context.environment !== 'production') {
    return null;
  }
  return notice;
});

airbrake.addFilter(notice => {
  if(notice.errors[0].message === 'Illegal invocation') {
    return null
  }
  return notice
})

airbrake.addFilter(notice => {
  if(window.navigator.userAgent.includes('FBNV/1')) {
    return null
  }
  return notice
})

export default airbrake
