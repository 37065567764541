import { matchesRuleCondition } from 'landing_pages/common/matches-rule-condition'
import { Campus, Client, ClientCampaignRule, ClientMapping, DegreeProgram, DegreeProgramRule, ExclusionRule, GetAnswerFunc, LeadTarget, ProgramGroup, RuleCondition } from 'landing_pages/types'

function isConditionMet(condition: RuleCondition, getAnswer: GetAnswerFunc): boolean {
  if(!condition) { return false }

  return typeof(condition) === 'boolean' ? condition : matchesRuleCondition(getAnswer(condition.key), condition)
}

export function areAllConditionsMet(conditions: RuleCondition[] | ExclusionRule, getAnswer: GetAnswerFunc): boolean {
  if(!conditions) { return false }

  if(Array.isArray(conditions)) {
    return conditions.every(condition => isConditionMet(condition, getAnswer))
  }

  return isConditionMet(conditions, getAnswer)
}

function isExcluded(clientOrProgram: Client | DegreeProgram, getAnswer: GetAnswerFunc): boolean {
  return clientOrProgram.exclusionRules?.some(conditions => areAllConditionsMet(conditions, getAnswer))
}

function getDegreeProgramsInProgramGroup(programGroupId: string, programGroups: ProgramGroup[]): DegreeProgram[] {
  return programGroups?.find(({id}) => String(id) === String(programGroupId))?.degreePrograms || []
}

function getCampus(leadTarget: LeadTarget | null, programGroups: ProgramGroup[]): Campus | undefined {
  return programGroups?.find(programGroup => programGroup.degreePrograms.some(degreeProgram => degreeProgram.id === leadTarget?.degreeProgramId))?.campus
}

function containsClient(clientMapping: ClientMapping, clientId: string): boolean {
  return clientMapping.some(entry => String(entry.clientId) === String(clientId))
}

function findMatchOrDefault<RuleType extends DegreeProgramRule | ClientCampaignRule>(rules: RuleType[], getAnswer: GetAnswerFunc): RuleType | undefined {
  const firstMatchingRule = rules.find(rule => (!rule.default && areAllConditionsMet(rule.conditions, getAnswer)))
  const defaultRule = rules.find(rule => !!rule.default)
  return firstMatchingRule || defaultRule
}

// Return a Set of degree program ids based on degreeProgramRules
function selectDegreePrograms(leadTargets: LeadTarget[], degreeProgramRules: DegreeProgramRule[], getAnswer: GetAnswerFunc): Set<string> {
  if(degreeProgramRules.length === 0) {
    return new Set(leadTargets.map(lt => lt.degreeProgramId))
  }

  const rule = findMatchOrDefault(degreeProgramRules, getAnswer)
  if(!rule) {
    return new Set()
  }
  return new Set([rule.degreeProgramId])
}

// Returns a clientMapping subset for the specified client based on clientCampaignRules
function selectClientMappings(clientCampaignRules: ClientCampaignRule[], client: Client, getAnswer: GetAnswerFunc) {
  const applicableRules = clientCampaignRules.filter(ccr => containsClient(ccr.clientMapping, client.id))
  const rule = findMatchOrDefault(applicableRules, getAnswer)

  if(!rule) {
    return []
  }

  return rule.clientMapping.filter(entry => String(entry.clientId) === String(client.id))
}

function findFirstLeadTarget(leadTargets: LeadTarget[], degreeProgramId: string, clientCampaignIds: string[]): LeadTarget | null {
  for(let i=0; i<clientCampaignIds.length; ++i) {
    const clientCampaignId = clientCampaignIds[i]
    const leadTarget = leadTargets.find(leadTarget => (
      String(clientCampaignId) === String(leadTarget.clientCampaignId) && String(degreeProgramId) === String(leadTarget.degreeProgramId)
    ))
    if(leadTarget) {
      return leadTarget
    }
  }

  return null
}

function selectMatchingLeadTargets(leadTargets: LeadTarget[], degreeProgramIds: Set<string>, clientMapping: ClientMapping, client: Client, getAnswer: GetAnswerFunc) {
  const matchingLeadTargets = clientMapping
    .flatMap(({programGroupId, clientCampaignIds}) => (
      getDegreeProgramsInProgramGroup(programGroupId, client.programGroups)
      .filter(degreeProgram => !isExcluded(degreeProgram, getAnswer))
      .filter(degreeProgram => degreeProgramIds.has(degreeProgram.id))
      .map(degreeProgram => findFirstLeadTarget(leadTargets, degreeProgram.id, clientCampaignIds))
    )).filter(leadTarget => Boolean(leadTarget))

  const firstCampus = getCampus(matchingLeadTargets[0], client.programGroups)
  const matchingLeadTargetsForCampus = matchingLeadTargets.filter(leadTarget => getCampus(leadTarget, client.programGroups)?.id === firstCampus?.id)

  return {
    ...client,
    leadTargets: matchingLeadTargetsForCampus,
    campus: firstCampus,
  }
}

type SelectLeadTargetsProps = {
  leadTargets: LeadTarget[]
  clientCampaignRules: ClientCampaignRule[]
  degreeProgramRules: DegreeProgramRule[]
  getAnswer: GetAnswerFunc
  clients: Client[]
}

// for each client not excluded by client exclusionRules
// find the first matching clientCampaignRule, or the default
// find all the degreePrograms in the clientCampaignRule that match degreeProgramRules and are not excluded by a program exclusionRule, or the default program
// for each degreeProgram, find the first lead target with the degreeProgram and its corresponding clientCampaign as determined by the clientCampaignRule's client_mapping
// discard any leadTargets whose campus doesn't match the first leadTarget's campus
// NOTE: clients are ordered by clientWeight
export function selectLeadTargets({ leadTargets, clientCampaignRules, degreeProgramRules, getAnswer, clients }: SelectLeadTargetsProps) {
  const degreeProgramIds = selectDegreePrograms(leadTargets, degreeProgramRules, getAnswer )
  const candidateClients = clients.filter(c => !isExcluded(c, getAnswer))

  return candidateClients.map(client => {
    const clientMapping = selectClientMappings(clientCampaignRules, client, getAnswer)
    return selectMatchingLeadTargets(leadTargets, degreeProgramIds, clientMapping, client, getAnswer)
  }).filter(result => result.leadTargets.length !== 0)
}
