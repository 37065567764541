import { on } from 'shared'
import { setAnswer } from 'landing_pages/form/functions'
import { getCity, getState } from 'shared/google_maps'

on('change', 'input[name="ZipCodeSource"]', event => {
  if(!window.google) {
    return
  }

  const zipCode = event.target.value
  if(zipCode.length !== 5) {
    return
  }

  new window.google.maps.Geocoder().geocode({componentRestrictions: {postalCode: zipCode, country: 'US'}}, results => {
    if(!results || !results[0]) {
      return
    }

    const city  = getCity(results[0].address_components)
    const state = getState(results[0].address_components)

    if(city && state) {
      setAnswer('City', city)
      setAnswer('State', state)
      setAnswer('ZIP_Code', zipCode)
    }
  })
})
