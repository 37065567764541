import { xVerify } from 'landing_pages/form/functions'
import { QuestionObject } from 'landing_pages/types'

async function validateQuestion(question: QuestionObject, answer: string): Promise<string | null> {
  if(question.type === 'email') {
    if(!answer.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/) || (window['useXVerify'] && !await xVerify('email', answer))) {
      return 'Enter a valid email address.'
    }
  } else if(question.type === 'tel') {
    if(!answer.replace(/[^0-9]/g, '').match(/^[0-9]{10}$/) || (window['useXVerify'] && !await xVerify('phone', answer))) {
      return 'Enter a valid phone number.'
    }
  } else if(question.type === 'zip') {
    if(!answer.match(/^[0-9]{5}$/)) {
      return 'Enter a valid zip code.'
    }
  } else {
    if(!answer) {
      return `Missing answer for ${question.text}`
    }
  }
  return null
}

export async function validate(questions: QuestionObject[], getAnswer: (key: string) => string): Promise<Record<string,string>> {
  const results: [string, string | null][] = await Promise.all(questions.map(async question => [question.key, await validateQuestion(question, getAnswer(question.key))]))
  const filteredResults = results.filter(([_key, error]) => !!error) as [string, string][]
  return Object.fromEntries(filteredResults)
}
