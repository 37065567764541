import Rails from '@rails/ujs'

import airbrake from 'shared/airbrake'
import onPageLoad from 'shared/on_page_load'

import 'landing_pages/form/functions'
import 'landing_pages/zip_code'
import 'landing_pages/address_autocomplete'
import 'landing_pages/tracking'
import 'landing_pages/modal'
import renderRoot from 'landing_pages/render'

Rails.start()

window.airbrake = airbrake;

onPageLoad(() => {
  renderRoot()
})
