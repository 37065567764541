const PersonSprintingIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <circle fill="none" cx="18" cy="18" r="18"/>
            <path d="M11.4 26 10 24.6l9.6-9.6H17v2h-2v-4h5.825c.267 0 .525.05.775.15.25.1.467.242.65.425l3 2.975c.45.45 1 .8 1.65 1.05.65.25 1.35.383 2.1.4v2c-1.033 0-1.97-.158-2.812-.475A6.407 6.407 0 0 1 23.95 18.1l-1-1.05-2.2 2.2L23 21.5l-6.55 3.775-1-1.725 4.3-2.475-1.7-1.7L11.4 26zM9 19v-2h5v2H9zm-2-3v-2h5v2H7zm18.475-2c-.55 0-1.025-.196-1.425-.588-.4-.391-.6-.862-.6-1.412 0-.55.2-1.02.6-1.412.4-.392.875-.588 1.425-.588s1.025.196 1.425.588c.4.391.6.862.6 1.412 0 .55-.2 1.02-.6 1.412-.4.392-.875.588-1.425.588zM9 13v-2h5v2H9z" fill="currentColor" fillRule="nonzero"/>
        </g>
    </svg>
  )
}

export default PersonSprintingIcon
