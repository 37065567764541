const SwissArmyIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <circle fill="none" cx="18" cy="18" r="18"/>
            <path d="M16.5 21.5h3V19H22v-3h-2.5v-2.5h-3V16H14v3h2.5v2.5zM18 28c-2.317-.583-4.229-1.913-5.737-3.988C10.754 21.937 10 19.633 10 17.1V11l8-3 8 3v6.1c0 2.533-.754 4.837-2.262 6.912C22.229 26.087 20.317 27.417 18 28z" fill="currentColor" fillRule="nonzero"/>
        </g>
    </svg>
  )
}

export default SwissArmyIcon
