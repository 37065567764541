import ProgressBar from 'landing_pages/components/UI/ProgressBar'
import type { Extents, LandingPageObject, QuestionObject } from 'landing_pages/types'
import { useEffect, useRef, useState } from 'preact/hooks';
import Question from '../../components/common/Question';
import useEvent from 'landing_pages/common/use-event'
import PreactButton from 'landing_pages/components/common/PreactButton';
import IconFlowError from 'landing_pages/components/UI/IconFlowError';
import { getVisibleQuestions } from 'landing_pages/common/get-visible-questions';
import { validate } from 'landing_pages/common/validate';
import PreactCheckbox from 'landing_pages/components/UI/PreactCheckbox';
import { trackQuestionCompleted, trackQuestionErrored } from 'landing_pages/tracking';

function buildSubsteps(questions: QuestionObject[]): QuestionObject[][] {
  return questions.reduce((result: QuestionObject[][], question: QuestionObject, currentIndex, array) => {
    const previousSubsteps = [...result]
    const currentSubstep = previousSubsteps.pop()
    const previousQuestion = array[currentIndex-1]

    if(!currentSubstep || question.type === 'icon_buttons' || previousQuestion.type === 'icon_buttons') {
      return [...result, [question]]
    }
    else {
      return [...previousSubsteps, [...currentSubstep, question]]
    }
  }, [])
}

function Substep({
  questions,
  onComplete,
  getAnswer,
  setAnswer,
  continueButtonText,
  pending,
  currentStep
} : {
  questions: QuestionObject[]
  onComplete: () => void
  getAnswer: (key: string) => string
  setAnswer: (key: string, value: string) => void
  continueButtonText: string
  pending: boolean
  currentStep: number
}) {
  const [isValidating, setIsValidating] = useState<boolean>(false)
  const [errors, setErrors] = useState<Record<string,string>>({})
  const errorMessage = Object.values(errors).join("\r\n")

  const validateGuard = useRef(false)

  const startValidation = useEvent(() => {
    if(validateGuard.current) {
      return
    }

    validateGuard.current = true
    setIsValidating(true)
    validate(questions, getAnswer)
      .then(errors => {
        setErrors(errors)
        if(Object.keys(errors).length) {
          Object.keys(errors).forEach(key => {
            const question = questions.find(q => q.key === key)
            trackQuestionErrored(question?.key, question?.text, errors[key], currentStep)
          })
        } else {
          onComplete()
        }
      })
      .finally(() => {
        validateGuard.current = false
        setIsValidating(false)
      })
  })

  const mapsAutoCompleteHandler = useEvent((props: Record<string,string>) => {
    for(const [key, value] of Object.entries(props)) {
      setAnswer(key, value)
    }
  })

  const autoAdvance = questions[0].type === 'icon_buttons'
  const showTcpa = questions.some(question => question.type === 'tel')
  const isAnyBlank = questions.some(question => !getAnswer(question.key)) || (showTcpa && !getAnswer('SecondaryTCPA'))
  const tcpa_disclosure = <>I acknowledge that, by clicking the checkbox as my official signature, I consent to representatives of Cygnus Education contacting me about educational opportunities via email, text, or phone, including my mobile phone number(s) entered above, using an automatic dialer, or pre-recorded message. Message and data rates may apply. I understand that my consent is not a requirement for enrollment, and I may withdraw my consent at any time.</>

  return (
    <div className="step">
      <div className="questions">
        {questions.map((question, index) => (
          <div className="question" key={`${question.key}-${index}`}>
            <Question
              question={question}
              value={getAnswer(question.key)}
              setValue={(newValue: string) => {
                setAnswer(question.key, newValue)
                if(autoAdvance) {
                  startValidation()
                }
              }}
              currentStep={currentStep}
              isError={question.key in errors}
              mapsAutoComplete={question.key === 'StreetAddress' ? mapsAutoCompleteHandler : undefined}
            />
          </div>
        ))}
        {showTcpa && (
          <PreactCheckbox className="tcpa_disclosure" label={tcpa_disclosure} isChecked={!!getAnswer('SecondaryTCPA')} onChange={event => {setAnswer('SecondaryTCPA', event.target.checked ? '1' : ''); trackQuestionCompleted('SecondaryTCPA', tcpa_disclosure.props.children, currentStep)}}/>
        )}
      </div>
      {!autoAdvance && (
        <PreactButton className="continue-button" onClick={startValidation} label={continueButtonText} isDisabled={isAnyBlank || isValidating || pending} isLoading={isValidating || pending} />
      )}
      {errorMessage &&
        <IconFlowError error={errorMessage} />
      }
    </div>
  )
}

export default function Step({
  pending,
  landingPage,
  extents,
  currentStep,
  numSteps,
  getAnswer,
  setAnswer,
  onCompleteStep,
} : {
  pending: boolean
  landingPage: LandingPageObject
  extents: Extents,
  currentStep: number
  numSteps: number
  getAnswer: (key: string) => string
  setAnswer: (key: string, value: string) => void
  onCompleteStep: () => void
}) {
  const step = landingPage.steps[currentStep]
  const visibleQuestions = getVisibleQuestions(step.questions, getAnswer)
  const subSteps = buildSubsteps(visibleQuestions)
  const [currentSubstep, setCurrentSubstep] = useState(0)
  const isFirstScreen = currentStep === 0 && currentSubstep === 0

  // dependent questions on separate steps can lead to steps with no questions, or to
  // currentSubstep being beyond the last subset in a step. in both cases, we advance
  // to the first substep of the next step
  useEffect(() => {
    if (visibleQuestions.length === 0 || currentSubstep > subSteps.length - 1) {
      if(currentStep < numSteps - 1) {
        setCurrentSubstep(0) // only reset current substep if Questionnaire is going to advance the current step
      }
      onCompleteStep()
    }
  }, [currentStep, currentSubstep, numSteps, onCompleteStep, subSteps.length, visibleQuestions.length])

  if (visibleQuestions.length === 0 || currentSubstep > subSteps.length - 1) {
    return null
  }

  return (
    <div className={ isFirstScreen ? "questionnaire-outer" : "questionnaire-outer push-down-footer"}>
      <div className="questionnaire-inner">
        { step.title &&
          <div className="step-title">
            <span>{step.title}</span>
          </div>
        }
        <ProgressBar percentComplete={100 * (currentStep + 1) / numSteps} />
        <Substep
          questions={subSteps[currentSubstep]}
          onComplete={() => {
            setCurrentSubstep(currentSubstep + 1)
          }}
          getAnswer={getAnswer}
          setAnswer={setAnswer}
          continueButtonText={currentStep === numSteps -1 && currentSubstep === subSteps.length -1 ? 'View Results' : 'Continue'}
          pending={pending}
          currentStep={currentStep}
        />
      </div>
      {currentStep === 0 && currentSubstep === 0 && (
        <>
          <h1>
            {landingPage.variables.heading}
          </h1>
          <h3>
            {extents['hl'] || landingPage.variables.subheading}
          </h3>
        </>
      ) || (
        <div className='vertical-space'></div>
      )}
    </div>
  )
}
