import onPageLoad from './on_page_load'
import airbrake from './airbrake'
import queryParams from './query_params'

function on(eventType, targetSelector, callback) {
  targetSelector = targetSelector.split(',').map(selector => `${selector.trim()},${selector.trim()} *`).join(',')
  eventType = eventType.split(',').map(type => type.trim())
  eventType.forEach(type => {
    document.addEventListener(type, event => {
      if(event.target.matches(targetSelector)) {
        return callback(event);
      }
    })
  })
}

export { airbrake, on, onPageLoad, queryParams }
