function weightedShuffle(array, getWeight) {
  // For each item:
  //   * If it has a weight, assign it priority 0 and a weighted random number
  //   * Otherwise, assign it priority 1 and a uniformly random number
  const objectsWithSortOrder = array.map(item => {
    const weight = getWeight(item)
    if(typeof(weight) === 'number' && weight > 0 && isFinite(weight)) {
      return [item, 0, -(Math.random() ** (1.0 / weight))]
    } else {
      return [item, 1, Math.random()]
    }
  })

  const sorter = ([_item1, priority1, rand1], [_item2, priority2, rand2]) => (priority1 - priority2) || (rand1 - rand2)

  return objectsWithSortOrder
    .sort(sorter)
    .map(([item, _priority, _rand]) => item)
}

export default weightedShuffle
