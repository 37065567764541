const CalendarBlankIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <circle fill="none" cx="18" cy="18" r="18"/>
            <path d="M11 28c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 9 26V12c0-.55.196-1.02.587-1.412A1.927 1.927 0 0 1 11 10h1V8h2v2h8V8h2v2h1c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v14c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 25 28H11zm0-2h14V16H11v10z" fill="currentColor" fillRule="nonzero"/>
        </g>
    </svg>
  )
}

export default CalendarBlankIcon
