const PersonWavingIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <circle fill="none" cx="18" cy="18" r="18"/>
            <path d="M15 28V14.775c-1.517-.4-2.729-1.238-3.637-2.513A7.172 7.172 0 0 1 10 8h2c0 1.383.446 2.562 1.338 3.537.891.975 2.029 1.463 3.412 1.463h2.5c.5 0 .967.092 1.4.275.433.183.825.45 1.175.8L26.35 18.6l-1.4 1.4L21 16.05V28h-2v-6h-2v6h-2zm3-16c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 0 1 16 10c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 18 8c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413s-.196 1.02-.587 1.412A1.927 1.927 0 0 1 18 12z" fill="currentColor" fillRule="nonzero"/>
        </g>
    </svg>
  )
}

export default PersonWavingIcon
