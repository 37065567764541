const ToolsIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <circle fill="none" cx="18" cy="18" r="18"/>
            <path d="m25.128 27-5.468-5.467 2.097-2.097 5.468 5.467L25.128 27zm-13.782 0L9.25 24.903l6.891-6.89-1.698-1.698-.699.699-1.273-1.273v2.047l-.7.699-3.02-3.021.7-.699h2.046l-1.248-1.248 3.545-3.545a3.14 3.14 0 0 1 1.074-.724c.383-.15.774-.225 1.173-.225.4 0 .79.075 1.174.225.382.15.74.39 1.073.724L15.99 12.27l1.249 1.249-.7.699 1.698 1.697 2.247-2.246a4.115 4.115 0 0 1-.162-.575c-.042-.2-.063-.4-.063-.599 0-.982.338-1.81 1.012-2.484C21.945 9.337 22.773 9 23.755 9c.25 0 .487.025.712.075.224.05.453.125.686.225L22.68 11.77l1.798 1.798 2.471-2.472c.117.233.196.462.238.686.041.225.062.462.062.712 0 .982-.337 1.81-1.01 2.484-.675.674-1.503 1.011-2.485 1.011-.2 0-.4-.016-.6-.05a2.292 2.292 0 0 1-.574-.174L11.346 27z" fill="currentColor" fillRule="nonzero"/>
        </g>
    </svg>
  )
}

export default ToolsIcon
