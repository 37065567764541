const CalendarPlusIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <circle fill="none" cx="18" cy="18" r="18"/>
            <path d="M23 28v-3h-3v-2h3v-3h2v3h3v2h-3v3h-2zm-12-2c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 9 24V12c0-.55.196-1.02.587-1.412A1.927 1.927 0 0 1 11 10h1V8h2v2h6V8h2v2h1c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v6.1a6.732 6.732 0 0 0-2 0V16H11v8h7c0 .333.025.667.075 1 .05.333.142.667.275 1H11z" fill="currentColor" fillRule="nonzero"/>
        </g>
    </svg>
  )
}

export default CalendarPlusIcon
