function getAddressComponent(address_components, types, field = 'long_name') {
  for(let i=0; i<types.length; ++i) {
    const type = types[i]
    const component = address_components.find(ac => ac.types.some(t => t === type))
    if(component) {
      return component[field]
    }
  }
  return ''
}

function getCity(address_components) {
  return getAddressComponent(address_components, ['locality', 'sublocality_level_1', 'neighborhood', 'administrative_area_level_3'])
}

function getState(address_components) {
  return getAddressComponent(address_components, ['administrative_area_level_1'], 'short_name')
}

function getZipCode(address_components) {
  return getAddressComponent(address_components, ['postal_code'])
}

export { getAddressComponent, getCity, getState, getZipCode }
