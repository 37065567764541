const PersonJoggingIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <circle fill="none" cx="18" cy="18" r="18"/>
            <path d="M20 28.5v-6l-2.1-2-1 4.4-6.9-1.4.4-2 4.8 1 1.6-8.1-1.8.7v3.4h-2v-4.7l3.95-1.7c.583-.25 1.013-.413 1.288-.488A2.92 2.92 0 0 1 19 11.5c.35 0 .675.092.975.275.3.183.542.425.725.725l1 1.6c.433.7 1.02 1.275 1.762 1.725.742.45 1.588.675 2.538.675v2c-1.1 0-2.129-.23-3.087-.688A7.323 7.323 0 0 1 20.5 16l-.6 3 2.1 2v7.5h-2zm.5-17.5c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 0 1 18.5 9c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 20.5 7c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413s-.196 1.02-.587 1.412A1.927 1.927 0 0 1 20.5 11z" fill="currentColor" fillRule="nonzero"/>
        </g>
    </svg>
  )
}

export default PersonJoggingIcon
