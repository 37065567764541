import { BaseLandingPage } from './LandingPage'
import HybridStep from 'landing_pages/templates/hybrid/Step'
import { getAnswer, setAnswer } from 'landing_pages/form/functions'
import Result from 'portals/components/Result'
import type { QuestionObject, StepObject } from '../types'

const makeRequest = async (extraData = {}) => {
  const formData = new FormData(document.getElementsByTagName('form')[0])
  Object.entries(extraData).forEach(([key, value]) => {
    formData.set(key, value)
  })

  const response = await fetch('/leads/internal', {
    method: 'POST',
    body:   formData,
  })

  if(response.status !== 200) {
    throw new Error(`Unexpected status: ${response.status}`)
  }

  return response.json()
}

function hideQuestions() {
  document.querySelectorAll('.questions').forEach(element => element.classList.add('hidden'))
}

function buildHybridStepDetails() {
  return [...document.querySelectorAll('.steps .step')].map(step => {
    const stepTitle = step?.querySelector('h1')?.dataset.template
    const questions : QuestionObject[] = []
    step.querySelectorAll('input, select').forEach(e => questions.push({text: (e.placeholder || e.ariaLabel || e.name)} as QuestionObject))
    return { title: stepTitle, questions: questions } as StepObject
  })
}

export default function LandingPage({ isMultiClient, variables, clients, clientWeights, leadTargets, clientCampaignRules, degreeProgramRules }) {
  return <BaseLandingPage
    landingPage={{
      id:            '',
      isMultiClient: isMultiClient,
      variables:     variables,
      steps:         buildHybridStepDetails(),
    }}
    makeRequest={makeRequest}
    getAnswer={getAnswer}
    setAnswer={setAnswer}
    clients={clients}
    clientWeights={clientWeights}
    leadTargets={leadTargets}
    clientCampaignRules={clientCampaignRules}
    degreeProgramRules={degreeProgramRules}
    stepComponent={HybridStep}
    resultComponent={Result}
    onComplete={isMultiClient ? hideQuestions : () => { return }}
  />
}
