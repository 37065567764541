export default function ProgressBar({percentComplete}) {
  const fillStyle = {
    width: `${percentComplete}%`,
  }

  return (
    <div className='icon-flow-progress-bar' >
      <div className='icon-flow-progress-fill' style={fillStyle} />
    </div>
  )
}
