import { useCallback, useRef } from 'preact/hooks'
import { MDCCheckbox } from '@material/checkbox'
import clsx from 'clsx'

export default function PreactCheckbox({label, onChange, className='', isDisabled=false, isChecked=false}) {
  const ref = useRef<MDCCheckbox>(null)

  const setRef = useCallback(select => {
    if(ref.current) {
      ref.current.destroy()
    }
    if(select) {
      ref.current = new MDCCheckbox(select)
    } else {
      ref.current = null
    }
  }, [])

  return (
    <div className={clsx("preact-checkbox-container", className)}>
      <div className={clsx("preact-checkbox", "mdc-checkbox", isDisabled && "mdc-checkbox--disabled")} ref={setRef}>
        <input type="checkbox" className="mdc-checkbox__native-control" id={`${label}-checkbox`} disabled={isDisabled} checked={isChecked} onClick={(event) => onChange(event)}/>
        <div className="mdc-checkbox__background">
          <svg className="mdc-checkbox__checkmark" viewBox="0 0 24 24">
            <path className="mdc-checkbox__checkmark-path" fill="none" d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
          </svg>
          <div className="mdc-checkbox__mixedmark"></div>
        </div>
        <div className="mdc-checkbox__ripple"></div>
        <div className="mdc-checkbox__focus-ring"></div>
      </div>
      <label htmlFor={`${label}-checkbox`}>{label}</label>
    </div>
  )
}
