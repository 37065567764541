const PersonIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <circle fill="none" cx="18" cy="18" r="18"/>
            <path d="M18 18c-1.1 0-2.042-.392-2.825-1.175C14.392 16.042 14 15.1 14 14s.392-2.042 1.175-2.825C15.958 10.392 16.9 10 18 10s2.042.392 2.825 1.175C21.608 11.958 22 12.9 22 14s-.392 2.042-1.175 2.825C20.042 17.608 19.1 18 18 18zm-8 8v-2.8c0-.567.146-1.088.438-1.563A2.914 2.914 0 0 1 11.6 20.55a14.866 14.866 0 0 1 3.15-1.163A13.776 13.776 0 0 1 18 19c1.1 0 2.183.129 3.25.387 1.067.259 2.117.646 3.15 1.163.483.25.87.612 1.162 1.087.292.475.438.996.438 1.563V26H10z" fill="currentColor" fillRule="nonzero"/>
        </g>
    </svg>
  )
}

export default PersonIcon
