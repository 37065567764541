import PreactSelect, { PreactSelectItem } from './PreactSelect'
import clsx from 'clsx'


export default function IconFlowSelect({ label, value, options, onChange, isError = false, icon = false, showLabel = true, large = false }) {
  // icons are only supported with the large select

  return (
    <PreactSelect
      className={clsx('icon-flow-select', { large: large || icon, icon: !!icon }, isError && "error" )}
      label={showLabel ? label : null}
      showLabel={showLabel}
      value={value ?? ''}
      onChange={onChange}
      icon={icon}
    >
      {showLabel && (<PreactSelectItem value='' isHidden hasIcon={!!icon}/>)}
      <PreactSelectItem value='' label={label} isDisabled={true} hasIcon={!!icon} className="menu-suggestion" />
        {options.map((option: string, index: number) => (
          <PreactSelectItem key={`${option}-${index}`} label={option} value={option} hasIcon={!!icon}/>
        ))}
    </PreactSelect>
  )
}
