const LaptopIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <circle fill="#FFF" cx="18" cy="18" r="18"/>
            <path d="M6 26.5v-2h2v-15h20v15h2v2H6zm10-2h4v-1h-4v1zm-6-3h16v-10H10v10zm0 0v-10 10z" fill="currentColor" fillRule="nonzero"/>
        </g>
    </svg>
  )
}

export default LaptopIcon
