import { RuleCondition } from "landing_pages/types"

export function matchesRuleCondition(answer: string, rule: RuleCondition) {
  if(typeof(rule) === 'boolean') {
    return rule
  }

  if(!answer) {
    return false
  }

  if(!Array.isArray(rule.value)) {
    return false
  }

  switch(rule.operator) {
    case 'less_than':
      return parseInt(answer) < parseInt(rule.value[0])
    case 'greater_than':
      return parseInt(answer) > parseInt(rule.value[0])
    case 'in':
      return rule.value.includes(answer)
    case 'not_in':
      return !rule.value.includes(answer)
  }
}
