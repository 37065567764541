import { QuestionObject } from "./Questionnaire"
import IconFlowIconButton from "../UI/IconFlowIconButton"
import Inputmask from 'inputmask'
import CheckIcon from "../svgIcons/CheckIcon"
import CloseIcon from "../svgIcons/CloseIcon"
import TwoLineIcon from "../svgIcons/TwoLineIcon"
import ThreeLineIcon from "../svgIcons/ThreeLineIcon"
import FiveLineIcon from "../svgIcons/FiveLineIcon"
import HalfLineIcon from "../svgIcons/HalfLineIcon"
import OneLineIcon from "../svgIcons/OneLineIcon"
import FourLineIcon from "../svgIcons/FourLineIcon"
import CalendarIcon from "../svgIcons/CalendarIcon"
import PersonIcon from "../svgIcons/PersonIcon"
import PersonSprintingIcon from "../svgIcons/PersonSprintingIcon"
import PersonJoggingIcon from "../svgIcons/PersonJoggingIcon"
import PersonWalkingIcon from "../svgIcons/PersonWalkingIcon"
import PersonWavingIcon from "../svgIcons/PersonWavingIcon"
import FastClockIcon from "../svgIcons/FastClockIcon"
import ClockIcon from "../svgIcons/ClockIcon"
import CalendarBlankIcon from "../svgIcons/CalendarBlankIcon"
import CalendarPlusIcon from "../svgIcons/CalendarPlusIcon"
import IconFlowSelect from "../UI/IconFlowSelect"
import LightningIcon from "../svgIcons/LightningIcon"
import WaterIcon from "../svgIcons/WaterIcon"
import FireIcon from "../svgIcons/FireIcon"
import LaptopIcon from "../svgIcons/LaptopIcon"
import BrainPowerIcon from "../svgIcons/BrainPowerIcon"
import JusticeIcon from "../svgIcons/JusticeIcon"
import QuillWritingIcon from "../svgIcons/QuillWritingIcon"
import SwissArmyIcon from "../svgIcons/SwissArmyIcon"
import ToolsIcon from "../svgIcons/ToolsIcon"
import { STATES } from "lib/states"
import PreactTextField, { MapsAutocompleteHandler } from "../UI/PreactTextField"
import { useCallback } from "preact/hooks"
import { trackQuestionCompleted } from "landing_pages/tracking"
import useEvent from 'landing_pages/common/use-event'


const iconMap = {
  'Citizen': [CheckIcon, CloseIcon],
  'Education': [OneLineIcon, TwoLineIcon, ThreeLineIcon, FourLineIcon, FiveLineIcon, HalfLineIcon],
  'HighestEducation': [OneLineIcon, TwoLineIcon, ThreeLineIcon],
  'HighSchool': [CheckIcon, CheckIcon, CloseIcon],
  'Employed': [CheckIcon, CloseIcon],
  'Age': PersonIcon,
  'GraduationYear': CalendarIcon,
  'Military': [CheckIcon, CloseIcon],
  'Military2': [PersonSprintingIcon, PersonJoggingIcon, PersonWalkingIcon, PersonWavingIcon],
  'Enroll': [FastClockIcon, ClockIcon, CalendarBlankIcon, CalendarPlusIcon],
  'Application': [CheckIcon, CloseIcon],
  'Interest': [LaptopIcon, BrainPowerIcon, JusticeIcon, QuillWritingIcon, SwissArmyIcon, ToolsIcon],
  'Trade': [LightningIcon, WaterIcon, FireIcon]
}

Inputmask.extendDefinitions({
  '2': {
    validator: '[2-9]',
  }
})

type QuestionProps = {
  question: QuestionObject,
  value: string,
  setValue: (value: string) => void,
  isError: boolean,
  mapsAutoComplete?: MapsAutocompleteHandler,
  currentStep: number,
}

function QuestionComponent({ question, value, setValue, isError, mapsAutoComplete, currentStep}: QuestionProps) {
  const onChange = useCallback(() => {
    trackQuestionCompleted(question.key, question.text, currentStep)
  }, [currentStep, question.key, question.text])

  const handleSelect = useEvent((value) => {
    setValue(value)
    onChange()
  })

  const type = question.type
  if(type === 'text' || type === 'email' || type === 'tel' || type === 'zip') {
    const inputMask = type === 'tel' ? '(299) 299-9999' : ''
    const inputType = type === 'zip' ? 'text' : type
    return <PreactTextField
      type={inputType}
      label={question.text}
      value={value}
      inputMask={inputMask}
      mapsAutoComplete={mapsAutoComplete}
      onInput={event => { setValue(event.target.value) }}
      onChange={onChange}
      isError={isError}
    />
  } else if(type === 'select' || type === 'state') {
    const Icon = iconMap[question.key]
    const options = type === 'state' ? STATES.map(_ => _.abbreviation) : question.options
    return (
      <IconFlowSelect
        label={question.text}
        icon={Icon && <Icon/>}
        value={value}
        options={options}
        onChange={handleSelect}
        showLabel={!Icon}
        isError={isError}
        large={!!Icon}
      />
    )
  } else {
    const options = question.options
    return (
      <>
        {options && options.map((option, index) => {
          const Icon = iconMap[question.key]?.[index]
          return (
            <div key={`${question.key}_option_${index}`} className="icon-button">
              <IconFlowIconButton
                text={option}
                icon={Icon && <Icon/>}
                onClick={() => { setValue(option); onChange() }}
                isSelected={value === option}
              />
            </div>
          )
        })}
      </>
    )
  }
}

export default function Question(props: QuestionProps) {
  return (
    <QuestionComponent {...props}/>
  )
}
