const CalendarIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <circle fill="none" cx="18" cy="18" r="18"/>
            <path d="M18 20a.965.965 0 0 1-.712-.288A.965.965 0 0 1 17 19c0-.283.096-.521.288-.713A.967.967 0 0 1 18 18a.97.97 0 0 1 .713.287A.97.97 0 0 1 19 19c0 .283-.096.52-.287.712A.968.968 0 0 1 18 20zm-4 0a.968.968 0 0 1-.713-.288A.967.967 0 0 1 13 19a.97.97 0 0 1 .287-.713A.97.97 0 0 1 14 18a.97.97 0 0 1 .713.287A.97.97 0 0 1 15 19c0 .283-.096.52-.287.712A.968.968 0 0 1 14 20zm8 0a.965.965 0 0 1-.712-.288A.965.965 0 0 1 21 19c0-.283.096-.521.288-.713A.967.967 0 0 1 22 18c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 22 20zm-4 4a.965.965 0 0 1-.712-.288A.965.965 0 0 1 17 23c0-.283.096-.52.288-.712A.965.965 0 0 1 18 22c.283 0 .521.096.713.288A.967.967 0 0 1 19 23c0 .283-.096.52-.287.712A.968.968 0 0 1 18 24zm-4 0a.968.968 0 0 1-.713-.288A.967.967 0 0 1 13 23c0-.283.096-.52.287-.712A.968.968 0 0 1 14 22c.283 0 .521.096.713.288A.967.967 0 0 1 15 23c0 .283-.096.52-.287.712A.968.968 0 0 1 14 24zm8 0a.965.965 0 0 1-.712-.288A.965.965 0 0 1 21 23c0-.283.096-.52.288-.712A.965.965 0 0 1 22 22c.283 0 .52.096.712.288A.965.965 0 0 1 23 23c0 .283-.096.52-.288.712A.965.965 0 0 1 22 24zm-11 4c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 9 26V12c0-.55.196-1.02.587-1.412A1.927 1.927 0 0 1 11 10h1V8h2v2h8V8h2v2h1c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v14c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 25 28H11zm0-2h14V16H11v10z" fill="currentColor" fillRule="nonzero"/>
        </g>
    </svg>
  )
}

export default CalendarIcon
