const BrainPowerIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <circle fill="none" cx="18" cy="18" r="18"/>
            <path d="M15.016 27v-3H12.02v-5h-1.498c-.166 0-.312-.087-.437-.262-.125-.175-.112-.355.038-.538l1.922-2.55c.083-1.767.774-3.317 2.072-4.65 1.298-1.333 2.93-2 4.893-2 1.93 0 3.578.683 4.943 2.05C25.318 12.417 26 14.067 26 16c0 1.417-.37 2.683-1.11 3.8a7.273 7.273 0 0 1-2.884 2.55V27h-6.99zm2.995-7h1.998l.15-1.25c.132-.05.249-.104.349-.163.1-.058.2-.137.3-.237l1.148.5.998-1.7-.998-.75a1.646 1.646 0 0 0 0-.8l.998-.75-.998-1.7-1.149.5c-.1-.1-.2-.18-.3-.238a2.325 2.325 0 0 0-.349-.162l-.15-1.25h-1.997l-.15 1.25c-.133.05-.249.104-.349.162-.1.059-.2.138-.3.238l-1.148-.5-.998 1.7.998.75a1.646 1.646 0 0 0 0 .8l-.998.75.998 1.7 1.149-.5c.1.1.2.179.3.237.099.059.215.113.349.163l.15 1.25zm.999-2.5c-.433 0-.79-.142-1.073-.425-.283-.283-.425-.642-.425-1.075 0-.433.142-.792.425-1.075.282-.283.64-.425 1.073-.425.433 0 .79.142 1.073.425.283.283.425.642.425 1.075 0 .433-.142.792-.425 1.075-.282.283-.64.425-1.073.425z" fill="currentColor" fillRule="nonzero"/>
        </g>
    </svg>
  )
}

export default BrainPowerIcon
