import { on } from 'shared'
import { resetSteps } from 'landing_pages/form/functions'

function showModal() {
  resetSteps();

  document.querySelectorAll('.modal-container, .modal-backdrop').forEach(element => {
    element.classList.add('visible')
  })
}

function dismissModal() {
  document.querySelectorAll('.modal-container, .modal-backdrop').forEach(element => {
    element.classList.remove('visible')
  })
}

on('click', '[data-modal]', event => {
  showModal();
})

document.addEventListener('keyup', event => {
  if(event.keyCode == 27) {
    dismissModal()
  }
})

on('click', '.modal-backdrop,.modal-close', event => {
  dismissModal()
})

// The modal container actually covers the backdrop, so dismiss the modal when the container is clicked, unless the click is within the modal itself.
on('click', '.modal-container', event => {
  if(!event.target.matches('.modal,.modal *')) {
    dismissModal()
  }
})
