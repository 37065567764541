import type{ ResultObject } from "landing_pages/components/LandingPage"
import type { Variables } from "landing_pages/types"

export function sanitize(html: string) {
  const wrapper = document.createElement('div')
  wrapper.innerHTML = html
  wrapper.querySelectorAll(':not(a):not(b):not(i):not(strong)').forEach(descendant => {
    descendant.parentNode?.removeChild(descendant)
  })
  wrapper.querySelectorAll('a').forEach(descendant => {
    descendant.setAttribute('target', '_blank')
  })
  return wrapper.innerHTML
}

export function InnerHTMLHelper({html}) {
  return <span dangerouslySetInnerHTML={{__html: sanitize(html)}}/>
}

export function isPresent(value: unknown) {
  switch(typeof(value)) {
    case 'boolean':
      return value
    case 'string':
      return !!value.trim()
    case 'object':
      return value !== null
    case 'undefined':
      return false
    default:
      return true
  }
}

export function presence<T>(value: T): T | null {
  return isPresent(value) ? value : null
}

export function dig<T>(object: object, path: string): T | null {
  const parts = path.split('.')
  for(let i=0; i<parts.length; ++i) {
    if(typeof(object) !== 'object') {
      return null
    }
    object = object[parts[i]]
  }
  return object as T
}

export function useExpandMacros(result: ResultObject) {
  return (template: string) => template.replace(/{school\.name}/g, result.campus.displayName || result.name)
}

export function useVariable(result: ResultObject, variables: Variables) {
  return <T,>(key: string, defaultValue: T): T | string => {
    const override = [dig<string>(variables, `clients.${result.id}.${key}`), dig<string>(variables, key)].find(item => (
      item !== undefined && item !== null
    ))

    if(override !== undefined && override !== null) {
      if(defaultValue === undefined || defaultValue === null || typeof(override) === typeof(defaultValue)) {
        return override
      }
    }

    return defaultValue
  }
}
