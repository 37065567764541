const FireIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <circle fill="none" cx="18" cy="18" r="18"/>
            <path d="M10 20c0-1.75.417-3.308 1.25-4.675S13 12.808 14 11.875c1-.933 1.917-1.646 2.75-2.138L18 9v3.3c0 .617.208 1.104.625 1.462.417.359.883.538 1.4.538.283 0 .554-.058.813-.175.258-.117.495-.308.712-.575L22 13c1.2.7 2.167 1.67 2.9 2.912.733 1.242 1.1 2.605 1.1 4.088 0 1.467-.358 2.804-1.075 4.012a8.085 8.085 0 0 1-2.825 2.863c.283-.4.504-.838.663-1.313.158-.475.237-.979.237-1.512 0-.667-.125-1.296-.375-1.887a4.956 4.956 0 0 0-1.075-1.588L18 17.1l-3.525 3.475a5.093 5.093 0 0 0-1.1 1.6A4.71 4.71 0 0 0 13 24.05c0 .533.08 1.037.238 1.512.158.475.379.913.662 1.313a8.085 8.085 0 0 1-2.825-2.863C10.358 22.804 10 21.467 10 20zm8-.1 2.125 2.075c.283.283.5.6.65.95.15.35.225.725.225 1.125 0 .817-.292 1.512-.875 2.087-.583.575-1.292.863-2.125.863s-1.542-.288-2.125-.863A2.821 2.821 0 0 1 15 24.05c0-.383.075-.754.225-1.112.15-.359.367-.68.65-.963L18 19.9z" fill="currentColor" fillRule="nonzero"/>
        </g>
    </svg>
  )
}

export default FireIcon
