export default function onPageLoad(callback) {
  // Document is already loaded
  if(document.readyState === 'complete') {
    callback();
  } else {
    document.addEventListener('readystatechange', function() {
      if(document.readyState === 'complete') {
        callback()
      }
    })
  }
}
