const FastClockIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <circle fill="none" cx="18" cy="18" r="18"/>
            <path d="M21 26c-2.233 0-4.125-.775-5.675-2.325C13.775 22.125 13 20.233 13 18c0-2.217.775-4.104 2.325-5.663C16.875 10.779 18.767 10 21 10c2.217 0 4.104.779 5.663 2.337C28.221 13.896 29 15.783 29 18c0 2.233-.779 4.125-2.337 5.675C25.104 25.225 23.217 26 21 26zm2.275-4.275L24.7 20.3 22 17.6V14h-2v4.425l3.275 3.3zM8 15v-2h4v2H8zm-1 4v-2h5v2H7zm1 4v-2h4v2H8z" fill="currentColor" fillRule="nonzero"/>
        </g>
    </svg>
  )
}

export default FastClockIcon
