const JusticeIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <circle fill="none" cx="18" cy="18" r="18"/>
            <path d="M8 27.5v-2h9V14.325a2.919 2.919 0 0 1-1.825-1.825H12l3 7c0 .833-.342 1.542-1.025 2.125-.683.583-1.508.875-2.475.875-.967 0-1.792-.292-2.475-.875S8 20.333 8 19.5l3-7H9v-2h6.175c.2-.583.558-1.063 1.075-1.438A2.908 2.908 0 0 1 18 8.5c.65 0 1.233.187 1.75.562.517.375.875.855 1.075 1.438H27v2h-2l3 7c0 .833-.342 1.542-1.025 2.125-.683.583-1.508.875-2.475.875-.967 0-1.792-.292-2.475-.875S21 20.333 21 19.5l3-7h-3.175A2.919 2.919 0 0 1 19 14.325V25.5h9v2H8zm14.625-8h3.75L24.5 15.15l-1.875 4.35zm-13 0h3.75L11.5 15.15 9.625 19.5zm8.375-7a.97.97 0 0 0 .713-.287A.97.97 0 0 0 19 11.5a.97.97 0 0 0-.287-.713A.97.97 0 0 0 18 10.5a.967.967 0 0 0-.712.287.968.968 0 0 0-.288.713c0 .283.096.521.288.713A.967.967 0 0 0 18 12.5z" fill="currentColor" fillRule="nonzero"/>
        </g>
    </svg>
  )
}

export default JusticeIcon
