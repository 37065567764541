import { useCallback, useEffect, useRef } from 'preact/hooks'
import { MDCSelect } from '@material/select'
import clsx from 'clsx'
import useEvent from 'landing_pages/common/use-event'

export function PreactSelectItem({ value, label='', isSelected=false, isDisabled=false, isHidden=false, hasIcon=false, className='' }) {
  return (
    <li className={clsx(className, "mdc-list-item", isDisabled && "mdc-list-item--disabled")} aria-selected={isSelected} data-value={value} aria-disabled={isDisabled} style={{display: isHidden && 'none'}} role="option">
      <span className="mdc-list-item__ripple"></span>
      { hasIcon && <span className="mdc-list-item__graphic"></span> }
      <span className="mdc-list-item__text">
        {label || value.toUpperCase()}
      </span>
    </li>
  )
}

export default function PreactSelect({className, label, value, onChange, showLabel=true, icon, children}) {
  const ref = useRef<MDCSelect>(null)

  const stableOnChange = useEvent(onChange)

  const setRef = useCallback(select => {
    if(ref.current) {
      ref.current.destroy()
    }
    if(select) {
      ref.current = new MDCSelect(select)
      ref.current.listen('MDCSelect:change', () => {
        stableOnChange(ref.current?.value)
      })
    } else {
      ref.current = null
    }
  }, [stableOnChange]) // stableOnChange is guaranteed to be stable; unfortunately, eslint-plugin-react-hooks doesn't know this.

  useEffect(() => {
    if(ref.current) {
      // Update the MDCSelect component's internal state
      ref.current.value = value
    }
  }, [value])

  return (
    <>
      <div className={clsx("preact-select", "mdc-select mdc-select--outlined", !showLabel && "mdc-select--no-label", className)} ref={setRef}>
        <div className="mdc-select__anchor" aria-labelledby={showLabel ? "outlined-select-label" : undefined}>
          <span className="mdc-notched-outline">
            <span className="mdc-notched-outline__leading"></span>
            <span className="mdc-notched-outline__notch">
              {showLabel && <span id="outlined-select-label" className="mdc-floating-label">{label}</span>}
            </span>
            <span className="mdc-notched-outline__trailing"></span>
          </span>
          {icon}
          <span className="mdc-select__selected-text-container">
            <span className="mdc-select__selected-text"></span>
          </span>
          <span className="mdc-select__dropdown-icon">
            <svg
                className="mdc-select__dropdown-icon-graphic"
                viewBox="7 10 10 5" focusable="false">
              <polygon
                  className="mdc-select__dropdown-icon-inactive"
                  stroke="none"
                  fillRule="evenodd"
                  points="7 10 12 15 17 10">
              </polygon>
              <polygon
                  className="mdc-select__dropdown-icon-active"
                  stroke="none"
                  fillRule="evenodd"
                  points="7 15 12 10 17 15">
              </polygon>
            </svg>
          </span>
        </div>

        <div className="mdc-select__menu mdc-menu mdc-menu-surface mdc-menu-surface--fullwidth">
          <ul className="mdc-list" role="listbox" aria-label={label}>
            {children}
          </ul>
        </div>
      </div>
    </>
  )
}
