export default function queryParams() {
  const regex = /(\?|&)([^&=]*)=([^&]*)/g
  let match
  const result = []
  while((match = regex.exec(window.location.search))) {
    const key = decodeURIComponent(match[2])
    const value = decodeURIComponent(match[3])

    result.push([key, value])
  }
  return result
}
