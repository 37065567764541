import {MDCRipple} from '@material/ripple';
import { useCallback, useRef } from 'preact/hooks';
import clsx from 'clsx';

type PreactButtonProps = {
  label?: string,
  isDisabled?: boolean,
  onClick?: () => void,
  className?: string,
  outlined?: boolean,
  isSelected?: boolean,
  isLoading?: boolean,
}

function Loader() {
  return (
    <>
      <div className="lds-roller">
        <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
      </div>
      Please wait...
    </>
  )
}

export default function PreactButton({ label, onClick, isDisabled, className, isSelected, isLoading }: PreactButtonProps) {
  const ref = useRef<MDCRipple>(null)

  const setRef = useCallback(button => {
    if(ref.current) {
      ref.current.destroy()
    }
    if(button) {
      ref.current = new MDCRipple(button)
    } else {
      ref.current = null
    }
  }, [])

  return (
    <button className={clsx(className, "mdc-button mdc-button--unelevated", isSelected && 'selected')} onClick={onClick} disabled={isDisabled || isLoading} ref={setRef}>
      <span className="mdc-button__label">
        {
          isLoading && <Loader />
          ||
          label
        }
      </span>
    </button>
  )
}
