import { on, onPageLoad } from 'shared'
import { setAnswer as setAnswerForForm } from 'landing_pages/form/functions'
import { getAddressComponent, getCity, getState, getZipCode } from 'shared/google_maps'
import { STATES, CA_PROVINCES } from 'lib/states'

/**
 * @typedef {object} CustomWindowObject
 * @property {any} [gm_authFailure]
 * @property {any} [google]
 */

const w =  /** @type CustomWindow */ (window)

/**
 * @typedef {Window & CustomWindowObject} CustomWindow
 */

// FIXME: This is tied to the DOM and not compatible with icon-flow
function clearAddress() {
  setAnswerForForm('StreetAddress', '')
  setAnswerForForm('City', '')
  setAnswerForForm('State', '')
  setAnswerForForm('ZIP_Code', '')
}

function createOption(text, value, selected) {
  const option = document.createElement('option')
  option.value = value
  option.selected = selected
  option.textContent = text
  return option
}

function createPlaceholderOption(label) {
  const option = createOption(label, '', false)
  option.style.display = 'none'
  return option
}

function labelFor(element) {
  if(element.id) {
    return document.querySelector(`label[for="${element.id}"]`)
  }
}

function setStateList(labelText, options) {
  const /** @type {HTMLSelectElement} */ select = document.querySelector('select[name="State"]')
  if(!select) {
    return
  }

  const currentValue = select.value
  while(select.firstChild !== null) {
    select.removeChild(select.firstChild)
  }

  select.appendChild(createPlaceholderOption(labelText))
  for(var i=0; i<options.length; ++i) {
    select.appendChild(createOption(options[i].name, options[i].abbreviation, options[i].abbreviation === currentValue))
  }

  select.classList.toggle('hidden', options.length === 0)

  const label = labelFor(select)
  if(label) {
    label.textContent = labelText
  }
}

function setZIPCode(label, validation) {
  const /** @type {HTMLInputElement} */ input = document.querySelector('input[name="ZIP_Code"]')
  if(!input) {
    return
  }

  input.placeholder = label
  input.pattern = validation
}

function setOtherCountry(show) {
  const /** @type {HTMLInputElement} */ input = document.querySelector('input[name="Country"]')
  if(!input) {
    return
  }

  input.classList.toggle('hidden', !show)
  input.disabled = !show
}

let streetAddressAutocomplete
let countryAutocomplete
let componentRestrictions = { country: ['us'] }

function setComponentRestrictions(newValue) {
  componentRestrictions = newValue
  if(streetAddressAutocomplete) {
    streetAddressAutocomplete.setComponentRestrictions(newValue)
  }
}

const autocompletes = []

w.gm_authFailure = function() {
  autocompletes.forEach(([autocomplete, input]) => {
    autocomplete.unbindAll()
    w.google.maps.event.clearInstanceListeners(input)
    input.disabled = false
    input.placeholder = 'Street Address'
    input.classList.remove('pac-target-input')
    input.classList.remove('gm-err-autocomplete')
    input.style.backgroundImage = null
  })
}

function setupAutocomplete(input, opts) {
  const options = opts.options
  const callback = opts.callback

  if(!input) {
    return
  }

  input.addEventListener('focus', () => { input.autocomplete = 'none' })

  const autocomplete = new w.google.maps.places.Autocomplete(input, options);

  autocompletes.push([autocomplete, input])

  autocomplete.addListener('place_changed', function() {
    var place = autocomplete.getPlace();
    if(place && place.address_components) {
      callback(place)
    }
  })

  return autocomplete
}

onPageLoad(() => {
  if(!w.google) {
    return
  }

  const streetAddressInput = document.querySelector('input[name="StreetAddress"]:not([data-autocomplete="false"])')
  bindStreetAddressAutocomplete(streetAddressInput, props => { for(const [key, value] of Object.entries(props)) { setAnswerForForm(key, value) } })

  const countryInput = document.querySelector('input[name="Country"]:not([data-autocomplete="false"])')
  bindCountryAutocomplete(countryInput, setAnswerForForm)
})

export function bindStreetAddressAutocomplete(streetAddressInput, callback) {
  if(streetAddressAutocomplete) {
    unbindStreetAddressAutocomplete()
  }

  streetAddressAutocomplete = setupAutocomplete(streetAddressInput, {
    options: {
      types: ['geocode'],
      fields: ['address_component'],
      componentRestrictions: componentRestrictions,
    },
    callback: (place) => {
      callback({
        'StreetAddress': `${getAddressComponent(place.address_components, ['street_number'])} ${getAddressComponent(place.address_components, ['route'], 'short_name')}`.trim(),
        'City':          getCity(place.address_components),
        'State':         getState(place.address_components),
        'ZIP_Code':      getZipCode(place.address_components),
      })
    }
  })
}

export function unbindStreetAddressAutocomplete() {
  if(streetAddressAutocomplete) {
    streetAddressAutocomplete.unbindAll()
  }
}

export function bindCountryAutocomplete(countryInput, setAnswer) {
  if(countryAutocomplete) {
    unbindCountryAutocomplete()
  }

  countryAutocomplete = setupAutocomplete(countryInput, {
    options: {
      types: ['country'],
      fields: ['address_component'],
    },
    callback: (place) => {
      setAnswer('Country', place.address_components.countryh)
    }
  })
}

export function unbindCountryAutocomplete() {
  if(countryAutocomplete) {
    countryAutocomplete.unbindAll()
  }
}

// FIXME: This is tied to the DOM and not compatible with icon-flow
on('change', 'select[name="Country"]', function(event) {
  clearAddress()

  if(event.target.value.length === 2) {
    setComponentRestrictions({ country: [event.target.value] })
  } else {
    setComponentRestrictions({ country: [] })
  }

  switch(event.target.value) {
    case 'us':
      setStateList('State', STATES)
      setZIPCode('ZIP Code', '\\d{5}')
      break
    case 'ca':
      setStateList('Province', CA_PROVINCES)
      setZIPCode('Postal Code', '[ABCEGHJ-NPRSTVXYabceghj-nprstvxy]\\d[ABCEGHJ-NPRSTV-Zabceghj-nprstv-z][ \\-]?\\d[ABCEGHJ-NPRSTV-Zabceghj-nprstv-z]\\d')
      break
    default:
      setStateList('State', [])
      setZIPCode('Postal Code', '.*')
  }

  setOtherCountry(event.target.value === 'other')
})
