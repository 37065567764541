const PersonWalkingIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <circle fill="none" cx="18" cy="18" r="18"/>
            <path d="m13 28.5 2.8-14.1-1.8.7v3.4h-2v-4.7l5.05-2.15c.233-.1.48-.158.738-.175a1.82 1.82 0 0 1 .737.1c.233.083.454.2.663.35.208.15.379.342.512.575l1 1.6c.433.7 1.02 1.275 1.762 1.725.742.45 1.588.675 2.538.675v2c-1.167 0-2.208-.242-3.125-.725a8.031 8.031 0 0 1-2.35-1.85L18.9 19l2.1 2v7.5h-2V22l-2.1-1.6-1.8 8.1H13zM19.5 11c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 0 1 17.5 9c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 19.5 7c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413s-.196 1.02-.587 1.412A1.927 1.927 0 0 1 19.5 11z" fill="currentColor" fillRule="nonzero"/>
        </g>
    </svg>
  )
}

export default PersonWalkingIcon
