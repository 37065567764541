import { selectLeadTargets } from 'landing_pages/common/select-lead-targets'
import { useCallback, useMemo, useReducer, useState } from 'preact/hooks'
import airbrake from 'shared/airbrake'
import weightedShuffle from 'shared/weighted_shuffle'
import Questionnaire from './common/Questionnaire'
import { Client, ClientCampaignRule, ClientWeight, DegreeProgramRule, ExclusionRule, Extents, LandingPageObject, LeadTarget, ProgramGroup } from '../types'
import useEvent from '../common/use-event'
import PortalIconStep from 'landing_pages/templates/icon-flow/Step'
import PortalIconResult from 'landing_pages/templates/icon-flow/Result'
import { FunctionComponent } from 'react'
import queryParams from 'shared/query_params'
import compact from 'landing_pages/common/compact'
import Results from './multi-client/Results'


// stylesheet allows use of mdc mixins
import 'styles/mdc-main'
import { KNOWN_PARAMS } from 'landing_pages/form/functions'

const LEAD_INFO_PHASE = 0
const RESULTS_PHASE = 1
const REDIRECT_PHASE = 2

type ReducerState = {
  phase: 0 | 1 | 2
  submitted: boolean
  pending: boolean
  redirect: {
    url: string
    priority: number | null
  }
}

type ReducerAction = {
  type: 'START_SUBMIT' | 'FINISHED_SUBMIT' | 'SHOW_RESULTS' | 'REDIRECTED'
} | {
  type: 'UPDATE_REDIRECT'
  redirect: {
    priority: number
    url: string
  }
}

const reducer = (state: ReducerState, action: ReducerAction) => {
  const newState = { ...state }

  switch(action.type) {
    case 'START_SUBMIT':
      newState.pending = true
      break

    case 'FINISHED_SUBMIT':
      newState.pending = false
      newState.submitted = true
      break

    case 'UPDATE_REDIRECT':
      if(state.redirect.priority === null || action.redirect.priority <= state.redirect.priority) {
        newState.redirect = action.redirect
      }
      break

    case 'SHOW_RESULTS':
      newState.phase = RESULTS_PHASE
      break

    case 'REDIRECTED':
      newState.phase = REDIRECT_PHASE
      newState.pending = true
      break
  }

  return newState
}

const initialState = (phase: 0|1|2) => ({
  phase,
  submitted: false,
  pending: false,
  redirect: {
    url: 'https://www.climbingup.org/',
    priority: null,
  },
})

type Response = {
  redirectTo: string
  redirectPriority: number
}

export type ResultObject = {
  id: string
  name: string
  logoUrl: string
  exclusionRules: ExclusionRule[]
  tcpaDisclosure: string
  implicitTcpa: boolean
  programGroups: ProgramGroup[]
  campaignType: string
  leadTargets: LeadTarget[]
  campus: {
    displayName: string
    logoUrl?: string
  }
}

export type ResultProps = {
  result: ResultObject
  onSkip: () => void
  onSubmit: (a?: Record<string,string>) => void
  pending: boolean
  showSkip: boolean
  firstName: string
  phone: string
  variables: Record<string,string>
}

export function BaseLandingPage({
  landingPage,
  extents,
  makeRequest,
  getAnswer,
  setAnswer,
  clients,
  clientWeights,
  clientCampaignRules,
  degreeProgramRules,
  leadTargets,
  stepComponent,
  resultComponent,
  onComplete
} : {
  landingPage: LandingPageObject
  extents: Extents
  makeRequest: (a: Record<string,string>) => Promise<Response>
  getAnswer: (key: string) => string
  setAnswer: (key: string, value: string) => void
  clients: Client[]
  clientWeights: ClientWeight[]
  clientCampaignRules: ClientCampaignRule[]
  degreeProgramRules: DegreeProgramRule[]
  leadTargets: unknown[]
  stepComponent: FunctionComponent
  resultComponent: FunctionComponent<ResultProps>
  onComplete?: () => void
}) {
  const [state, dispatch] = useReducer(reducer, null, () => initialState(LEAD_INFO_PHASE))

  const sortedClients = useMemo(() => weightedShuffle(clients, (client: Client) => clientWeights.find(cw => String(cw.clientId) === String(client.id))?.weight), [clients, clientWeights])
  // Can't memoize because it's dependent on global state (answers)
  const visibleResults = selectLeadTargets({ leadTargets, clientCampaignRules, degreeProgramRules, getAnswer, clients: sortedClients })

  const handleRedirect = useEvent(() => {
    if(state.redirect.priority === null) {
      airbrake.notify('No redirect URL received')
    }
    dispatch({ type: 'REDIRECTED' })
    location.href = state.redirect.url
  })

  const submitForm = useEvent(async (extraData = {}) => {
    dispatch({ type: 'START_SUBMIT' })
    try {
      const data = await makeRequest(extraData)
      dispatch({ type: 'UPDATE_REDIRECT', redirect: { url: data.redirectTo, priority: data.redirectPriority } })
    }
    finally {
      dispatch({ type: 'FINISHED_SUBMIT' })
    }
  })

  const submitFiltered = useEvent(async () => {
    try {
      await submitForm({ filtered: true })
    }
    finally {
      handleRedirect()
    }
  })

  const handleCompletedQuestionnaire = useEvent(async () => {
    if(typeof(onComplete) === 'function') {
      onComplete()
    }

    const visibleResults = selectLeadTargets({ leadTargets, clientCampaignRules, degreeProgramRules, getAnswer, clients: sortedClients })
    if(visibleResults.length === 0) {
      return submitFiltered()
    }
    else {
      if(landingPage.isMultiClient) {
        dispatch({ type: 'SHOW_RESULTS' })
      }
      else {
        const leadTarget = visibleResults[0].leadTargets[0]
        try {
          await submitForm({ program_id: leadTarget.degreeProgramId, campaign_id: leadTarget.clientCampaignId })
        }
        finally {
          handleRedirect()
        }
      }
    }
  })

  if(landingPage.isMultiClient && state.phase !== LEAD_INFO_PHASE && visibleResults.length !== 0) {
    const allowSkip = Boolean(Object.fromEntries(queryParams())['skip'])

    return (
      <Results
        variables={landingPage.variables}
        results={visibleResults}
        submitForm={submitForm}
        submitFiltered={submitFiltered}
        doRedirect={handleRedirect}
        pending={state.pending}
        allowSkip={allowSkip}
        resultComponent={resultComponent}
        getAnswer={getAnswer}
      />
    )
  }

  return (
    <Questionnaire
      stepComponent={stepComponent}
      submitFiltered={submitFiltered}
      onComplete={handleCompletedQuestionnaire}
      pending={state.pending || state.submitted}
      landingPage={landingPage}
      extents={extents}
      getAnswer={getAnswer}
      setAnswer={setAnswer}
      exclusionRules={window['exclusionRules']}
    />
  )
}

interface TrackingParamsBase {
  google_experiments?: Record<string, string>
}

interface TrackingParams extends TrackingParamsBase {
  [key: string]: unknown
}

interface LeadHiddenFields {
  preview?: string | false
  test?: boolean
  extents: Extents
  landing_page_id: string
  subid?: string
  source?: string
  cid?: string
  xxTrustedFormCertUrl?: string
  leadid_token?: string
  tracking_params?: Record<string, unknown>
}

function buildHiddenFields(landing_page_id: string, extents: Extents) {
  const params = Object.fromEntries(queryParams())

  const trackingParams : TrackingParams = {}

  function addExperimentHiddenField(value: string, name: string) {
    trackingParams.google_experiments ||= {}
    trackingParams.google_experiments[name] = value
  }

  /* global gtag */
  gtag('event', 'optimize.callback', {
    callback: addExperimentHiddenField
  })

  queryParams().forEach(([key, value]) => {
    if(!KNOWN_PARAMS.includes(key)) {
      trackingParams[key] = value
    }
  })

  const hiddenFields = {
    preview: params['preview'] || false,
    test: !!params['test'],
    extents: extents,
    landing_page_id: landing_page_id,
    subid: params['subid'],
    source: params['source'],
    cid: params['cid'],
    xxTrustedFormCertUrl: (document.querySelector('input[id="xxTrustedFormCertUrl"]') as (HTMLInputElement | null))?.value,
    leadid_token: (document.querySelector('input[id="leadid_token"]') as (HTMLInputElement | null))?.value,
    tracking_params: trackingParams,
  }

  return compact(hiddenFields satisfies LeadHiddenFields)
}

export default function LandingPage({ landingPage, answers }: { landingPage: LandingPageObject, answers: Record<string,string> }) {
  const [formData, setFormData] = useState<Record<string,string>>(() => (answers || {}))

  // TODO: Compute these on the client side if possible. Otherwise pass them in as data attributes, rather than globally on `window`.
  const clients = window['clients']
  const clientWeights = window['clientWeights']
  const leadTargets = window['leadTargets']
  const clientCampaignRules = window['clientCampaignRules']
  const degreeProgramRules = window['degreeProgramRules']

  const getAnswer = useCallback((key: string) => formData[key], [formData])
  const setAnswer = useCallback((key: string, value: string) => { formData[key] = value; setFormData({ ...formData }) }, [formData])

  const extents: Extents = Object.fromEntries(queryParams().filter(([key, _value]) => ['hl'].includes(key)))

  const makeRequest = useCallback(async (extraData: Record<string,string>) => {
    const hiddenFields = buildHiddenFields(landingPage.id, extents)

    const response = await fetch('/leads/internal', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body:   JSON.stringify({ ...formData, ...hiddenFields, ...extraData }),
    })

    if(response.status !== 200) {
      throw new Error(`Unexpected status: ${response.status}`)
    }

    return response.json()
  }, [extents, formData, landingPage.id])


  return (
    <>
      <BaseLandingPage
        landingPage={landingPage}
        extents={extents}
        makeRequest={makeRequest}
        getAnswer={getAnswer}
        setAnswer={setAnswer}
        clients={clients}
        clientWeights={clientWeights}
        clientCampaignRules={clientCampaignRules}
        degreeProgramRules={degreeProgramRules}
        leadTargets={leadTargets}
        stepComponent={PortalIconStep}
        resultComponent={PortalIconResult}
      />
    </>
  )
}
