// @ts-nocheck
import { isBlank } from './form/functions'

let tracked = false

export function trackNextStep(stepDetails, currentStep, totalSteps) {
  if(!tracked) {
    tracked = true

    window.fbq('track', 'ViewContent')
    window.gtag('event', 'ViewContent')
    typeof(window.snaptr) === 'function' && window.snaptr('track', 'VIEW_CONTENT')
    window.ttq?.track('ViewContent')
  }

  trackStepCompleted(stepDetails, currentStep, totalSteps)
}

function trackStepCompleted(stepDetails, currentStep, totalSteps) {
  const questions = stepDetails.questions.map(q => q.text).join('; ')

  window.gtag('event', 'step_completed', {
    'step_title': stepDetails.title,
    'questions': questions,
    'step_number': currentStep + 1,
    'total_steps': totalSteps,
  });
}

export function trackQuestionErrored(name, text, error, currentStep) {
  window.gtag('event', 'question_errored', {
    'question_name': name,
    'question_text': text,
    'error': error,
    'step_number': currentStep + 1,
  });
}

export function trackHybridQuestionErrored(element, currentStep) {
  let error = ''
  if(isBlank(element)) {
    error = 'No message (field empty)'
  } else if(!element.checkValidity()) {
    error = 'No message (field invalid)'
  }

  trackQuestionErrored(element.name, element.placeholder, error, currentStep)
}

export function trackQuestionCompleted(name, text, currentStep) {
  window.gtag('event', 'question_completed', {
    'question_name': name,
    'question_text': text,
    'step_number': currentStep + 1,
  });
}
