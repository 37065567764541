const ClockIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <circle fill="none" cx="18" cy="18" r="18"/>
            <path d="m21.3 22.7 1.4-1.4-3.7-3.7V13h-2v5.4l4.3 4.3zM18 28a9.732 9.732 0 0 1-3.9-.788 10.092 10.092 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.732 9.732 0 0 1 8 18c0-1.383.263-2.683.788-3.9a10.092 10.092 0 0 1 2.137-3.175c.9-.9 1.958-1.613 3.175-2.138A9.743 9.743 0 0 1 18 8c1.383 0 2.683.262 3.9.787a10.106 10.106 0 0 1 3.175 2.138c.9.9 1.612 1.958 2.137 3.175A9.732 9.732 0 0 1 28 18a9.732 9.732 0 0 1-.788 3.9 10.092 10.092 0 0 1-2.137 3.175c-.9.9-1.958 1.612-3.175 2.137A9.732 9.732 0 0 1 18 28z" fill="currentColor" fillRule="nonzero"/>
        </g>
    </svg>
  )
}

export default ClockIcon
