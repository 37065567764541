import { getIn } from 'formik'

function normalizeSortSpec(sortSpec) {
  let result

  if(typeof(sortSpec) === 'object') {
    result = sortSpec
  } else {
    result = { key: sortSpec }
  }

  if(typeof(result.key) === 'string') {
    const key = result.key
    result.key = value => getIn(value, key)
  }

  return result
}

function findMap(array, callback) {
  for(var item of array) {
    const result = callback(item)
    if(result) {
      return result
    }
  }
}

function compare(a, b, { nullsFirst = false, desc = false } = {}) {
  const aIsNull = a === null || a === undefined
  const bisNull = b === null || b === undefined

  if(aIsNull && !bisNull) {
    return nullsFirst ? -1 : 1
  }

  if(!aIsNull && bisNull) {
    return nullsFirst ? 1 : -1
  }

  if(aIsNull && bisNull) {
    return 0
  }

  if(typeof(a) === 'number' && typeof(b) === 'number') {
    return desc ? b - a : a - b
  }

  if(desc) {
    return String(b).localeCompare(String(a))
  } else {
    return String(a).localeCompare(String(b))
  }
}

// Sort an array by one or more sort specs, where each sort spec is:
//    * a callback function, called on each element in the array
//    * an attribute name, passed to getIn() with each element of the array
//    * an object of the form { key, desc, nullsFirst }, where:
//        * key is either a callback function or an attribute name, as above
//        * desc is a boolean indicating whether or not the sort is in descending order
//        * nullsFirst is a boolean indicating whether null/undefined values should come first (default is last)
//
// The function returns a copy of the array sorted in the specified order. The first
// sort spec takes the highest precedence, and ties are broken by the second (or third, etc.).
export default function sortBy(array, ...sortSpecs) {
  sortSpecs = sortSpecs.map(sortSpec => normalizeSortSpec(sortSpec))

  return [...array].sort((a, b) => (
    findMap(sortSpecs, sorter => compare(sorter.key(a), sorter.key(b), sorter)) || 0
  ))
}
