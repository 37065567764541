import { render } from 'preact'
import LandingPage from 'landing_pages/components/LandingPage'
import HybridLandingPage from 'landing_pages/components/HybridLandingPage'

function getRootContainer() {
  return document.querySelector('.root-container')
}

export default function renderRoot() {
  const rootContainer = getRootContainer()
  if(!rootContainer) {
    return
  }

  const landingPageJSON = rootContainer.getAttribute('data-landing-page')
  const answersJSON = rootContainer.getAttribute('data-answers')
  if(landingPageJSON) {
    render(
      <LandingPage
        landingPage={JSON.parse(landingPageJSON)}
        answers={answersJSON ? JSON.parse(answersJSON) : null}
      />, rootContainer, rootContainer.querySelector('.root') ?? undefined
    )
  }
  else {
    render(
      <HybridLandingPage
        isMultiClient={window['templateName'] === 'portal'}
        variables={window['variables']}
        clients={window['clients']}
        clientWeights={window['clientWeights']}
        leadTargets={window['leadTargets']}
        clientCampaignRules={window['clientCampaignRules']}
        degreeProgramRules={window['degreeProgramRules']}
      />, rootContainer, rootContainer.querySelector('.root') ?? undefined
    )
  }
}
