import {MDCRipple} from '@material/ripple';
import { useCallback, useRef } from 'preact/hooks';
import clsx from 'clsx';


export default function IconFlowIconButton({text, icon=null, onClick=null, isSelected}) {
  const ref = useRef<MDCRipple>(null)

  const setRef = useCallback(button => {
    if(ref.current) {
      ref.current.destroy()
    }
    if(button) {
      ref.current = new MDCRipple(button)
    } else {
      ref.current = null
    }
  }, [])

  return (
    <button className={clsx('icon-flow-icon-button', "mdc-button mdc-button--raised", isSelected && 'selected', !icon && "no-icon")} onClick={onClick} ref={setRef}>
      {icon}
      <div className='button-text'>
        {text}
      </div>
    </button>
  )
}
