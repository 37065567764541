const LightningIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <circle fill="none" cx="18" cy="18" r="18"/>
            <path d="m16.275 27 1-7H13l6.275-11h1l-1 7h4.275l-6.275 11z" fill="currentColor" fillRule="nonzero"/>
        </g>
    </svg>
  )
}

export default LightningIcon
