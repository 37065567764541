const WaterIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <circle fill="none" cx="18" cy="18" r="18"/>
            <path d="M25.375 14.65c-.45.45-.967.792-1.55 1.025a4.681 4.681 0 0 1-1.75.35 4.645 4.645 0 0 1-1.725-.337 4.327 4.327 0 0 1-1.525-1.038l-1.875-1.875a2.488 2.488 0 0 0-.85-.563 2.64 2.64 0 0 0-1.95 0c-.317.125-.6.313-.85.563l-1.875 1.875L10 13.225l1.875-1.875c.45-.45.958-.788 1.525-1.013a4.553 4.553 0 0 1 3.438 0 4.52 4.52 0 0 1 1.512 1.013l1.875 1.875a2.564 2.564 0 0 0 1.85.775c.333 0 .663-.063.988-.188.325-.125.62-.32.887-.587l1.875-1.875 1.425 1.425-1.875 1.875zm0 5c-.45.45-.963.787-1.538 1.012A4.725 4.725 0 0 1 22.1 21a4.725 4.725 0 0 1-1.737-.338 4.442 4.442 0 0 1-1.538-1.012l-1.875-1.875a2.488 2.488 0 0 0-.85-.563 2.64 2.64 0 0 0-1.95 0c-.317.125-.6.313-.85.563l-1.875 1.875L10 18.25l1.875-1.9c.45-.45.958-.788 1.525-1.013a4.553 4.553 0 0 1 3.438 0 4.52 4.52 0 0 1 1.512 1.013l1.875 1.875a2.564 2.564 0 0 0 1.85.775c.333 0 .663-.063.988-.188.325-.125.62-.32.887-.587l1.875-1.875 1.425 1.425-1.875 1.875zm-.025 5c-.45.45-.958.788-1.525 1.013A4.645 4.645 0 0 1 22.1 26a4.737 4.737 0 0 1-1.737-.337 4.454 4.454 0 0 1-1.538-1.013l-1.9-1.875a2.488 2.488 0 0 0-.85-.563 2.64 2.64 0 0 0-1.95 0c-.317.125-.6.313-.85.563L11.4 24.65l-1.4-1.4 1.875-1.9c.45-.45.958-.788 1.525-1.013a4.553 4.553 0 0 1 3.438 0 4.52 4.52 0 0 1 1.512 1.013l1.875 1.875c.267.267.563.462.888.587.325.125.654.188.987.188a2.564 2.564 0 0 0 1.85-.775l1.875-1.875 1.4 1.425-1.875 1.875z" fill="currentColor" fillRule="nonzero"/>
        </g>
    </svg>
  )
}

export default WaterIcon
