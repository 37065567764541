const OneLineIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <circle fill="none" cx="18" cy="18" r="18"/>
            <path fill="currentColor" d="M14 17h8v2h-8z"/>
        </g>
    </svg>
  )
}

export default OneLineIcon
