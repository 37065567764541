import ErrorIcon from "../svgIcons/ErrorIcon";

export default function IconFlowError({ error }) {
  return (
    <div className='icon-flow-error'>
      <div>{ErrorIcon()}</div>
      <div className='error-text'>{error}</div>
    </div>
  )
}
